@media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
      color: #000000 !important;
    }
    .size-a6 {
        width: 148mm;
        height: 217mm;
        margin-top: 15px;
        padding-top: 15px;
        position: relative;
        page: size-a6;
    }
    .size-a6 .no-border {
      border: none;
    }
    .size-a6 .col-wrap {
      padding: 12px 30px;
    }
    .size-a6 .label-title {
      font-weight: 700;
      line-height: 1.1;
      padding: 0;
      margin: 0;
    }
    .logo-ekspedisi {
        width: 80px;
        padding-top: 20px;
    }
  }
  
  @media print {
    .page-break {
      margin-top: 3rem;
      display: block;
      page-break-before: auto;
    }
  }
  
  @page size-a6{
    size: 105mm 148mm;
    margin: 0;
  }